import React, { useEffect } from "react";
import SignInUpPageWrapper from "@components/SignInUpPageWrapper";
import SignInUpPageFormContainer from "@components/SignInUpPageFormContainer";
import SignInForm from "@components/forms/SignInForm";
import { loginUser } from "@actions/accountActions";
import { useDispatch, useSelector } from "react-redux";
import { initAppBrowser } from "@actions/initApp";
import { useLocation, useHistory } from "react-router-dom";

const SignIn = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const signedIn = useSelector(state => state.initAppReducer.signedIn);
  const customerInfoThunkRunning = useSelector(
    state => state.customerInfo._customerInfoThunkRunning
  );

  useEffect(() => {
    dispatch(initAppBrowser(["messages"]));
  }, []);

  useEffect(() => {
    if (signedIn && !customerInfoThunkRunning) {
      history.push("/");
    }
  }, [customerInfoThunkRunning]);

  const submissionHistoryObject = {
    props: {
      history,
      location
    }
  };
  return (
    <SignInUpPageWrapper
      title={`Login | CardCash`}
      metaProperty={"og:url"}
      metaContent={"https://cardcash.com/login/"}
    >
      <SignInUpPageFormContainer
        header={"Login"}
        page={"signIn"}
        submissionHistoryObject={submissionHistoryObject}
      >
        <SignInForm
          setClassName={"sign-in-form-main"}
          recaptchaTarget={"recaptchaTarget"}
          submissionHistoryObject={submissionHistoryObject}
          login={(email, password, reCaptcha, context) =>
            dispatch(loginUser(email, password, reCaptcha, context))
          }
        />
      </SignInUpPageFormContainer>
    </SignInUpPageWrapper>
  );
};

export default SignIn;
